const PATTERNS = {
    /** Default do sistema */
    "NFS-e Nacional": "NFS-e Nacional",
    /** O padrão IPM é utilizado na prefeitura de Cascavel-PR */
    "IPM": "IPM",
    /** O padrão IMP da cidade de Araranguá-SC */
    "IPM (Araranguá)": "IPM (Araranguá)",
    /** O padrão IMP da cidade de Brusque-SC */
    "IPM (Brusque)": "IPM (Brusque)",
    /** Padrão do município de João Pessoa-PE */
    "PMJP": "PMJP",
    "ABACO": "ABACO",
    "NF PAULISTANA": "NF PAULISTANA",
    "DUETO": "DUETO",
    "Betha 2.0": "Betha 2.0",
    "GINFES": "GINFES",
    "Lexsom": "Lexsom",
    "Elotech": "Elotech",
    "Bauhaus": "Bauhaus",
    "Boanf": "Boanf",
    "PortalFacil": "PortalFacil",
    "GIAP": "GIAP",
    "INFISC": "INFISC",
    "Equiplano": "Equiplano",
    "SMARAPDSil WS2": "SMARAPDSil WS2",
    "ISS.NET": "ISS.NET",
    "SIGCORP Londrina": "SIGCORP Londrina",
    "HM2 Soluções": "HM2 Soluções",
    "ISSWEB Fiorilli": "ISSWEB Fiorilli",
    "JFISS Digital": "JFISS Digital",
    "SIGCORP ABRASF": "SIGCORP ABRASF",
    "ISS.NET (Brasília)": "ISS.NET (Brasília)",
    "Nota Salvador": "Nota Salvador",
    "Governo Eletrônico": "Governo Eletrônico",
    /**
     * ## Obrigatoriedades
     * - Informar valor no item da lista de serviço;
     * - Informar valor no ISS Retido;
     * - Informar o tipo de retenção do ISS (verificar tabela abaixo).
     * | Valor   | Prefeitura   | Descrição  |
     * |:-------:|:------------:|:----------|
     * | 1       | M            | Mensal     |
     * | 2       | F            | Anual      |
     *
     * - O campo de natureza da operação deverá seguir o padrão da prefeitura:
     * | Valor    | Prefeitura   | Descrição  |
     * |:--------:|:------------:|:-----------|
     * | 12       | N            | Simples Nacional
     * | 17       | E            | Micro Empreendedor Individual (MEI)
     *
     **/
    "Prescon WS": "Prescon WS",
    "Memory": "Memory",
    "ISS On-line Supernova": "ISS On-line Supernova",
    "Nota Carioca": "Nota Carioca",
    "SEFINNet": "SEFINNet",
    "Governo Digital": "Governo Digital",
    "WEB ISS 2.0": "WEB ISS 2.0",
    "E&L": "E&L",
    "CENTI 2": "CENTI 2",
    "Governa TXT": "Governa TXT",
    "Nota Natalense": "Nota Natalense",
    "TIPLAN 2.0": "TIPLAN 2.0",
    "SimplISS": "SimplISS",
    "THEMA": "THEMA",
    "Síntese Tecnologia": "Síntese Tecnologia",
    "ISS Fortaleza": " ISS Fortaleza",
    "Tecnos": "Tecnos",
    "Embras Siap.Net": "Embras Siap.Net",
    "Futurize": "Futurize",
    "Goiânia": "Goiânia",
    "Assessor Público": "Assessor Público",
    "Assessor Público (Lins-SP)": "Assessor Público (Lins-SP)",
    "Assessor Público (Votorantim-SP)": "Assessor Público (Votorantim-SP)",
    "SJP": "SJP",
    "ISS4R": "ISS4R",
    "CECAM": "CECAM",
}

export type NFSE_PATTERN = keyof typeof PATTERNS;

export const NFSE_PATTERNS = Object.keys(PATTERNS) as NFSE_PATTERN[];

